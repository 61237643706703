import "./App.css";
import MyComponent from "./Gradient";
import fbIcon from "./icons8-facebook.svg";
import instagramIconSrc from "./icons8-instagram.svg";
import twitterIconSrc from "./icons8-twitter.svg";
import youtubeIconSrc from "./icons8-youtube.svg";
import tiktokIconSrc from "./icons8-tiktok.svg";
import arrowIconSrc from "./arrow.svg";
import "./styles.css";

const icons: { src: string; url: string }[] = [
  {
    src: fbIcon,
    url: "https://www.facebook.com/RFKJR.Voicelift",
  },
  {
    src: instagramIconSrc,
    url: "https://www.instagram.com/rfkjr_voicelift/",
  },
  {
    src: twitterIconSrc,
    url: "https://twitter.com/RFKJr_Voicelift",
  },
  {
    src: youtubeIconSrc,
    url: "https://www.youtube.com/channel/UCXyOgLhOnlEVCsaMr9Ru9tQ",
  },
  {
    src: tiktokIconSrc,
    url: "https://www.tiktok.com/@rfkjr_voicelift",
  },
];

function App() {
  const arrowIcon = <img className="arrow-icon" src={arrowIconSrc} />;

  return (
    <div className="App">
      <div className="background">
        <MyComponent />
      </div>
      <div className="main-content">
        <div className="content-container">
          <p className="header">
            <b>VoiceLift</b> uses AI technology to restore and enhance speech.
          </p>
          <p className="examples">
            Hear examples of RFK jr.'s voice repaired by VoiceLift below.
          </p>
          <p className="welcome-note">
            <span>We welcome questions, comments, suggestions.</span>
            <span className="contact">
              {arrowIcon} <span className="contact-email">stephen@middy.com</span>
            </span>
          </p>
          <p className="footer">
            <span className="copyright">&copy; 2023 voicelift.co</span>
            <span className="social-icons">
              {icons.map((icon, i) => (
                <a key={i} className="social-icon" href={icon.url}>
                  <img src={icon.src} />
                </a>
              ))}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
