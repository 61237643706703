import React, { useEffect } from "react";

const colors1 = ["#26292b", "#6ec3f4", "#eae2ff", "#6eaa6f"];

const MyComponent: React.FC = () => {
  useEffect(() => {
    // @ts-ignore
    new Gradient({
      canvas: "#my-canvas-id",
      colors: colors1// ["#a960ee", "#ff333d", "#90e0ff", "#ffcb57"],
    });
  }, []); // Empty dependency array to make sure this effect only runs once after the first render

  return (
    <canvas
      style={{
        height: "100%",
        width: "100%",
      }}
      id="my-canvas-id"
    ></canvas>
  );
};

export default MyComponent;
